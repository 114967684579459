<template lang="pug">
.notification
  notifications.bottom-right(
    group="BottomRightNotif"
    position="bottom right"
  )
    template(
      slot="body"
      slot-scope="props"
    )
      .notification-container(
        :class="`vue-notif-type-${props.item.type}`"
      )
        .close(
          @click="props.close"
        )
          i.icon.icon-cross-line
        .notification-title(v-html="props.item.title")
        div(
          class="notification-content"
          v-html="props.item.text"
        )
        template(v-if="showReport")
          BaseButton.btn-report(
            isText=true
            @click="reportIssue(props.item.text)"
          ) Report
  notifications.top-center(
    group="TopCenterNotif"
    position="top center"
    :width="isMobile ? '100%' : '50%'"
  )
    template(
      slot="body"
      slot-scope="props"
    )
      .notification-container(
        :class="`vue-notif-type-${props.item.type}`"
      )
        .close(
          @click="props.close"
        )
          i.icon.icon-cross-line
        div(
          class="notification-content"
          v-html="props.item.text"
        )
        template(v-if="showReport")
          BaseButton.btn-report(
            isText=true
            @click="reportIssue(props.item.text)"
          ) Report
  notifications.notification--loading.top-center(
    group="LoadingTopCenterNotif"
    position="top center"
    :width="isMobile ? '100%' : '50%'"
  )
    template(
      slot="body"
      slot-scope="props"
    )
      .notification-container(
        :class="[`vue-notif-type-${props.item.type}`, { 'is-centered': props.item.type === 'loading' }]"
      )
        .close(
          @click="props.close"
        )
          i.icon.icon-cross-line
        Lottie.preloader(
          v-if="hasPreloader(props)"
          :width="30"
          :options="options"
        )
        div(
          class="notification-content"
          v-html="props.item.text"
        )
        template(v-if="showReport")
          BaseButton.btn-report(
            isText=true
            @click="reportIssue(props.item.text)"
          ) Report
  notifications.bottom-center(
    group="BottomCenterNotif"
    position="bottom center"
  )
    template(
      slot="body"
      slot-scope="props"
    )
      .notification-container(
        :class="`vue-notif-type-${props.item.type}`"
      )
        .close(
          @click="props.close"
        )
          i.icon.icon-cross-line
        div(
          class="notification-content"
          v-html="props.item.text"
        )
        template(v-if="showReport")
          BaseButton.btn-report(
            isText=true
            @click="reportIssue(props.item.text)"
          ) Report

  notifications.notification--upload.bottom-left(
    group="UploadBottomLeftNotif"
    position="bottom left"
  )
    template(
      slot="body"
      slot-scope="props"
    )
      .notification-container(
        :class="[`vue-notif-type-${props.item.type}`]"
      )
        .close(
          @click="props.close"
        )
          i.icon.icon-cross-line
        div.notification-content__container
          div.notification-content(
            v-html="props.item.text"
          )
          BaseButton.notification__try-again-button(
            v-if="isShowTryAgainButton(props)"
            is-text=true
            is-canvas=true
            @click="doReUpload(props)"
          ) Try again
        template(v-if="showReport")
          BaseButton.btn-report(
            isText=true
            @click="reportIssue(props.item.text)"
          ) Report
</template>

<script>
import { isMobile } from '@/assets/scripts/utilities';
import preloaderMobile from '@/assets/data/preloader-mobile.json';
import Lottie from '@/components/ProjectCanvas/AssetSidebarCanvas/LottieShared.vue';

export default {
  name: 'Notification',
  data() {
    return {
      isMobile: isMobile(),
      options: {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: preloaderMobile,
      },
    };
  },
  components: {
    Lottie,
  },
  methods: {
    reportIssue(message) {
      if (this.$freshchat) {
        this.$freshchat.open({ replyText: `Hi, I'm facing an issue:\r\n\r\n"${message.replace(/(<([^>]+)>)/ig, '')}"` });
      }
    },
    doReUpload(props) {
      const getFileType = props.item.text.split(' ')[0];
      props.close();
      this.$emit('do-reupload', getFileType);
    },
    hasPreloader(props) {
      return typeof props !== 'undefined'
      && typeof props.item !== 'undefined'
      && typeof props.item.data !== 'undefined'
      && props.item.data.hasPreloader;
    },
    isShowTryAgainButton(props) {
      return typeof props !== 'undefined'
      && typeof props.item !== 'undefined'
      && typeof props.item.data !== 'undefined'
      && props.item.data.hasTryAgain;
    },
  },
  computed: {
    showReport() {
      return typeof this.props !== 'undefined'
      && typeof this.props.item !== 'undefined'
      && typeof this.props.item.data !== 'undefined'
      && typeof this.props.item.data.showReport;
    },
  },
};
</script>

<style lang="scss">
.vue-notification-group {
  z-index: 2147483002 !important;
  background: transparent;

  .vue-notification-wrapper {
    margin-bottom: 12px;
    box-shadow: 0 18px 16px rgba(10, 31, 68, 0.2);
  }

  &.top-center {
    top: 25px !important;

    @include mobile() {
      padding: 0 20px;
    }

    &.notification--loading {
      top: 6px !important;

      .vue-notification-wrapper {
        box-shadow: none;
        overflow: visible; // so the box-shadow won't be cutted off
      }

      .preloader {
        margin-right: 15px;
      }

      .is-centered {
        padding: 10px 15px;
        padding-right: 40px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
      }

      .close {
        top: 15px !important;
      }
    }

    .mobile-project & {
      top: 80px !important;
    }

    .notification-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      padding-right: 40px;
      border-radius: 4px;

      .notification-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        i {
          margin-right: 10px;
          font-size: 1.15rem;
        }
      }

      .close {
        top: 18px;
        right: 20px;
        font-size: 0.75rem;

        @include mobile() {
          right: 40px;
        }
      }
    }
  }

  &.bottom-right {
    .notification-content {
      margin-bottom: 10px;
    }
  }

  &.bottom-center {
    text-align: center;
    margin-bottom: 80px;
  }

  &.notification--upload {
    width: 280px !important;
    left: 110px !important;

    @include smallest {
      width: 225px !important;
      left: 92px !important;
    }

    .vue-notification-wrapper {
      overflow: visible;
    }

    .vue-notif-type-error {
      .notification-content__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .notification-content {
        max-width: 70%;
      }
    }

    .notification-container {
      padding: 18px 25px;
      font-size: 0.875rem;
      border-radius: 50px;

      @include smallest {
        padding: 15px 20px;
        font-size: 0.75rem;
      }
    }

    .close {
      top: -12px !important;
      right: 5px !important;
      font-size: 0.6875rem !important;
      padding: 7px 7px 5px;
      color: $light;
      background-color: $darkGrey4;
      border-radius: 50%;
      transition: background-color 0.3s ease-in-out;

      @include smallest {
        top: -8px !important;
        right: 4px !important;
        font-size: 0.6125rem !important;
        padding: 5px 5px 3px;
      }

      &:hover {
        background-color: darken($darkGrey4, 5%);
      }
    }

    .notification__try-again-button {
      margin: 0;
      border: 0;
      color: $light;
      font-size: 0.875rem;
      font-weight: 500;
      outline: 0;
      padding: 0;
      cursor: pointer;
      transition: color 0.3s ease-in-out;

      @include smallest {
        font-size: 0.75rem;
      }

      &:hover {
        color: $lightGrey3;
      }

      span {
        text-decoration: underline;
      }
    }
  }

  .vue-notif-type-success{
    background-color: $green;
    color: $light;
  }

  .vue-notif-type-error{
    background-color: $red;
    color: $light;
  }

  .vue-notif-type-warn{
    background-color: $amber;
    color: $textBlack;
  }

  .vue-notif-type-loading {
    background-color: $darkGrey700;
    color: $light;
    text-transform: capitalize;
  }

  .notification-container {
    padding: 20px;
    font-size: 0.75rem;
    position: relative;

    .preloader,
    .preloader + div {
      display: inline-block;
      vertical-align: middle;
    }

    .preloader {
      margin: -5px 8px -5px -5px !important;
    }

    &.is-centered {
      width: 200px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }

    .mobile-project & {
      padding: 10px 20px;
      border-radius: 30px;
      font-size: 0.875rem;
      display: inline-flex;
      align-items: center;
      align-content: center;
      justify-content: center;

      &.is-centered {
        display: flex;
      }
    }

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      font-size: 1rem;

      .mobile-project & {
        display: none;
      }
    }

    .notification-title {
      margin-bottom: 10px;
      font-weight: 700;
    }

    .btn-report {
      font-size: 1em;
      font-weight: 700;
      color: $light;
      text-transform: uppercase;
    }
  }
}
</style>
